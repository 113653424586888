<template>
    <ImageOptimized
        class="w-full rounded-lg object-cover"
        :src="image"
        :alt="imageAlt"
        :loading="imageLoading"
        :fetchpriority="fetchPriority"
    />
</template>

<script>
export default {
    name: 'ImageGeneral',
    props: {
        image: {
            required: false,
            type: String,
            default: null,
        },
        imageAlt: {
            required: false,
            type: String,
            default: '',
        },
        imageLoading: {
            required: false,
            type: String,
            default: 'lazy', // eager, lazy
        },
        fetchPriority: {
            required: false,
            type: String,
            default: 'low', // high, low, auto
        },
    },
};
</script>
