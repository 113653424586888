<template>
    <section class="section-image" :class="color">
        <LayoutContained :class="padding">
            <LayoutMedia :flip="flip">
                <template v-slot:media>
                    <ImageGeneral
                        v-if="image || imageMobile"
                        class="hidden md:block"
                        :image="image || imageMobile"
                        :imageAlt="imageAlt"
                        :class="imageAspectRatio"
                        :imageLoading="imageLoading"
                        :fetchPriority="fetchPriority"
                    ></ImageGeneral>
                    <ImageGeneral
                        v-if="imageMobile || image"
                        class="block md:hidden"
                        :image="imageMobile || image"
                        :imageAlt="imageAlt"
                        :class="imageAspectRatio"
                        :imageLoading="imageLoading"
                        :fetchPriority="fetchPriority"
                    ></ImageGeneral>
                </template>
                <template v-slot:text v-if="heading || content">
                    <ContentHeading
                        v-if="heading"
                        :heading="heading"
                        :headingColor="headingColor"
                        :headingComponent="headingComponent"
                        :marginBottom="content ? 'mb-6' : 'mb-0'"
                        :textCentered="textCentered"
                    ></ContentHeading>
                    <ContentSubheading
                        v-if="subheading"
                        :heading="subheading"
                        :headingColor="subheadingColor"
                        :headingComponent="subheadingComponent"
                        :marginBottom="content ? 'mb-6' : 'mb-0'"
                        :textCentered="SubheadingTextCentered"
                    ></ContentSubheading>
                    <ContentText
                        v-if="content"
                        :html="content"
                        :contentColor="contentColor"
                        :textCentered="textCentered"
                    ></ContentText>
                    <div v-if="buttonText" class="mt-8" :class="!!textCentered ? 'text-center' : ''">
                        <ButtonPrimary
                            :buttonColor="buttonColor || undefined"
                            :buttonRoute="buttonRoute"
                            :buttonText="buttonText"
                            :buttonClick="buttonClick"
                            :buttonClickParams="buttonClickParams"
                            :buttonHref="buttonHref"
                            :buttonIcon="buttonIcon"
                        ></ButtonPrimary>
                    </div>
                    <slot name="custom"></slot>
                </template>
            </LayoutMedia>
            <slot name="buttons"></slot>
        </LayoutContained>
    </section>
</template>

<script>
export default {
    name: 'SectionImage',
    props: {
        padding: {
            required: false,
            default: 'py-16 sm:py-20',
            type: String,
        },
        buttonColor: {
            required: false,
            type: String,
            default: null,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonHref: {
            required: false,
            type: String,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonIcon: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
        content: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-gray-600',
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-gray-900',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        subheading: {
            required: false,
            type: String,
            default: null,
        },
        subheadingColor: {
            required: false,
            type: String,
            default: 'text-gray-900',
        },
        subheadingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        SubheadingTextCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        image: {
            required: false,
            type: String,
            default: null,
        },
        imageMobile: {
            required: false,
            type: String,
            default: null,
        },
        imageAlt: {
            required: false,
            type: String,
            default: '',
        },
        imageAspectRatio: {
            required: false,
            type: String,
            default: null
        },
        imageLoading: {
            required: false,
            type: String,
            default: undefined,
        },
        fetchPriority: {
            required: false,
            type: [String, undefined],
            default: undefined,
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        flip: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
};
</script>
